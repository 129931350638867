import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import {
	Section,
	SectionActions,
	SectionBody,
	SectionFooter,
	SectionHeader,
	SectionTitle,
} from '@playbooks/interface/sections';
import { bountyParams, serialize } from 'api';
import { BountyDisplayActions } from 'components/bounty/bounty-display-actions';
import { BountyList } from 'components/bounty/bounty-list';
import { useStore } from 'contexts';
import { useQuery } from 'hooks';
import { Pagination } from 'molecules/paginations';
import { AlgoliaService } from 'services';

const BountyDisplaySection = ({
	type = 'display',
	title,
	api,
	search,
	rootApi = '',
	rootLink = '',
	options,
	views,
	pagination,
	tailwind,
}) => {
	const [bounties, setBounties] = useState([]);
	const [params, setParams] = useState({ ...bountyParams, view: 'recent' });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const [query, setQuery] = useState('');
	const router = useRouter();
	const store = useStore();

	// Computed
	const client = new AlgoliaService({ tableName: 'bounties' });
	const computedQuery = useMemo(() => serialize(router.query, ['status', 'variant', 'view']), [router.query]);

	// Hooks
	useEffect(() => {
		fetchData();
	}, [router.query, query, params.page]);

	// Actions
	const [fetchData, fetchingData] = useQuery(async () => {
		if (pagination?.type === 'btn' && params.page !== 0) return;
		const response = query ? await searchDb() : await fetchDb();
		setBounties(response.data);
		setMeta(response.meta);
	});

	// Methods
	const fetchDb = async () => {
		return await store.query({ url: `${rootApi}/bounties`, params: { ...params, ...api, ...computedQuery } });
	};

	const searchDb = async () => {
		return await client.queryIndex(query, { ...params, ...search, ...computedQuery });
	};

	// Render
	return (
		<Section border='' borderRadius='' spacing='py-4' {...tailwind?.section}>
			<SectionHeader border='' spacing='py-4' {...tailwind?.header}>
				{title ? title : <SectionTitle>{meta.totalRecords || bounties.length} Results</SectionTitle>}
				<SectionActions {...tailwind?.actions}>
					<BountyDisplayActions
						params={{ ...params, ...api }}
						setParams={setParams}
						query={query}
						setQuery={setQuery}
						options={options}
						views={views}
					/>
				</SectionActions>
			</SectionHeader>
			<SectionBody size='lg' spacing='py-4' {...tailwind?.body}>
				<BountyList
					type={type}
					bounties={bounties}
					loading={fetchingData}
					rootLink={rootLink}
					tailwind={tailwind?.list}
				/>
			</SectionBody>
			<SectionFooter border='' spacing='py-4' {...tailwind?.footer}>
				<Pagination meta={meta} params={params} setParams={setParams} {...pagination} />
			</SectionFooter>
		</Section>
	);
};

export { BountyDisplaySection };
