import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { BreadcrumbItem, BreadcrumbLink, Breadcrumbs } from '@playbooks/interface/breadcrumbs';
import { capitalize } from 'utils';

const AppBreadcrumbs = ({ tailwind }) => {
	const router = useRouter();

	// Computed
	const breadcrumbs = useMemo(() => {
		const paths = router.asPath.split('?')[0].split('/');
		const appPaths = paths.slice(1, paths.length);
		return appPaths.map((path, i) => ({
			title: parseInt(path) ? `${path}` : path,
			href: '/' + appPaths.slice(0, i + 1).join('/'),
			active: appPaths.length === i + 1,
		}));
	}, [router.asPath]);

	// Methods
	const isActive = active => {
		return active ? { active: '', hover: '' } : {};
	};

	// Render
	return (
		<Breadcrumbs border='border-b' borderRadius='' spacing='px-4 py-3' {...tailwind?.breadcrumbs}>
			<BreadcrumbItem>
				<BreadcrumbLink icon='house' href='/' />
			</BreadcrumbItem>
			{breadcrumbs.map((breadcrumb, i) => (
				<BreadcrumbItem key={i} arrow={breadcrumbs.length !== i + 1} {...tailwind?.item}>
					<BreadcrumbLink
						href={breadcrumb.href}
						active={breadcrumb.active}
						{...isActive(breadcrumb.active)}
						{...tailwind?.link}>
						{capitalize(breadcrumb.title)}
					</BreadcrumbLink>
				</BreadcrumbItem>
			))}
		</Breadcrumbs>
	);
};

export { AppBreadcrumbs };
