import { Col, Grid } from '@playbooks/interface/grid';
import { Div, Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { TextLink } from '@playbooks/interface/links';
import {
	Section,
	SectionBody,
	SectionFooter,
	SectionHeader,
	SectionPretitle,
	SectionText,
	SectionTitle,
} from '@playbooks/interface/sections';
import { gradientSpan } from '@playbooks/interface/utils';

const AboutWhatSection = () => {
	// Render
	return (
		<Section bgColor='bg-gray-100 dark:bg-gray-800' border='' spacing='px-12 my-12'>
			<SectionHeader align='text-center' border='border-b' display='block' space='space-y-4' spacing='py-8'>
				<SectionPretitle>About</SectionPretitle>
				<SectionTitle display='' size='h2'>
					<Span>Building a new developer</Span>
					<Span {...gradientSpan}>economy</Span>
				</SectionTitle>
			</SectionHeader>
			<SectionBody spacing='py-12'>
				<Grid>
					<Col lg='4'>
						<Div space='space-y-2'>
							<FadIcon icon='brackets-curly' fontSize='text-2xl' color='gray-500 dark:gray-400' spacing='mb-2' />
							<SectionTitle>Hybrid source software</SectionTitle>
							<SectionText fontSize='text-sm' leading='!leading-relaxed'>
								Playbooks is a digital marketplace where developers can buy, sell, and exchange software seamlessly. Our
								platform offers an alternative to open source letting developers monetize their time and expertise. We
								call it hybrid source software.
							</SectionText>
						</Div>
					</Col>
					<Col lg='4'>
						<Div space='space-y-2'>
							<FadIcon icon='people-group' fontSize='text-2xl' color='gray-500 dark:gray-400' spacing='mb-2' />
							<SectionTitle>Support independent developers</SectionTitle>
							<SectionText fontSize='text-sm' leading='!leading-relaxed'>
								Developers are the unsung heros of the internet and your time should be respected the same as any other
								industry. That's why we're building a new economy for developers that celebrates your creativity while
								fostering a collaborative and inclusive environment.
							</SectionText>
						</Div>
					</Col>
					<Col lg='4'>
						<Div space='space-y-2'>
							<FadIcon icon='chart-simple' fontSize='text-2xl' color='gray-500 dark:gray-400' spacing='mb-2' />
							<SectionTitle>An economy that works</SectionTitle>
							<SectionText fontSize='text-sm' leading='!leading-relaxed'>
								At Playbooks we're building an open, transparent, and rewarding economy that works for everyone. That's
								why 70% of every sale goes to content creators (and their contributors) with another 10% dedicated to
								community projects via the bounty program.
							</SectionText>
						</Div>
					</Col>
				</Grid>
			</SectionBody>
			<SectionFooter border='' display='flex-middle' spacing='pb-8'>
				<TextLink size='' fontSize='text-sm' hover='h:underline' nextIcon='chevron-right' href='/about'>
					Learn more
				</TextLink>
			</SectionFooter>
		</Section>
	);
};

export { AboutWhatSection };
